import { React, Component } from 'react';
import NoteModal from '../../components/note-modal';
import {
    Button,
    Row,
    Col,
} from 'reactstrap';

import img1 from '../../assets/images/big/leafy-note.png';
import img2 from '../../assets/images/big/new-note.png';

class Notes extends Component {

    state = {
        showNote: false,
    };

    newNote = () => {
        console.log (this.state.showNote)
        this.setState({showNote: true})
        console.log (this.state.showNote)
    }

    render() {

        return (
            <div>
                <div className="d-flex justify-content-between">
                <div className="h3">Notes</div>
                <Button className="btn mb-4" color="success" onClick={()=> this.newNote()}><i className="mr-2 mdi mdi-note-plus"/>New note</Button>
                </div>
                <Row>
                    <Col xs="12" md="4">
                        <NoteModal
                            img={img1}
                            noteTitle="Your first note"
                            noteSubtitle="Let's see what LeafNotes can do..."
                            noteBody="Use markdown to create content. Drag and drop images into the body, link notes and more!"
                        />
                    </Col>
                    { this.state.showNote ?  
                    <Col xs="12" md="4">   
                        <NoteModal
                            img={img2}
                            noteTitle="Edit your brand new note"
                            noteSubtitle="This is a blank note - it can be anything you dream."
                            noteBody="Your best ideas go here. But don't be afraid to add any old thing that comes into your head."
                        />
                    </Col>
                    : null }
                </Row>
            </div>
        );
    }
}

export default Notes;
