import React, { Component } from "react";
import InviteButton from "../../components/invite-modal";
import { Card, CardBody, Table, Button } from "reactstrap";
import { createPortal } from "react-dom";
import Frame from 'react-frame-component';
import './../../assets/scss/style.css';

const container = document.getElementById("chatframe");

const avatars = {
  one: "https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg",
  two: "https://mdbootstrap.com/img/new/avatars/8.jpg",
  three: "https://mdbootstrap.com/img/new/avatars/1.jpg",
  four: "https://mdbootstrap.com/img/new/avatars/12.jpg",
  five: "https://mdbootstrap.com/img/new/avatars/10.jpg",
};

class Chat extends Component {
  state = {
    showInvite: false,
    showChat: false,
  };

  showInvite = () => {
    console.log(this.state.showInvite);
    this.setState({ showInvite: true });
    console.log(this.state.showInvite);
  };

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between">
          <div className="">
            <h3>Chat</h3>
          </div>
          <InviteButton
            inviteAction="Send"
            inviteTitle="Invite a team member"
            inviteBody="name@trychameleon.com"
          />
        </div>

        <Card>
            <CardBody className="">
              <div className="">
                <Table hover borderless>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Username</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          style={{ maxWidth: 25 }}
                          className="rounded-circle mr-2"
                          alt="100x100"
                          src={avatars.two}
                          data-holder-rendered="true"
                        ></img>
                        David Pines
                        <i className="mdi mdi-checkbox-blank-circle text-success small ml-2" />
                      </td>
                      <td>@davidpines</td>
                      <td>
                        <i className="fa-lg mdi mdi-message-outline" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          style={{ maxWidth: 25 }}
                          className="rounded-circle mr-2"
                          alt="100x100"
                          src={avatars.five}
                          data-holder-rendered="true"
                        ></img>
                        Anna Ficus
                        <i className="mdi mdi-checkbox-blank-circle text-success small ml-2" />
                      </td>
                      <td>@annaannaanna</td>
                      <td>
                        <i className="fa-lg mdi mdi-message-outline" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          style={{ maxWidth: 25 }}
                          className="rounded-circle mr-2"
                          alt="100x100"
                          src={avatars.three}
                          data-holder-rendered="true"
                        ></img>
                        Edgar Iris
                        <i className="mdi mdi-checkbox-blank-circle text-secondary small ml-2" />
                      </td>
                      <td>@eiris</td>
                      <td>
                        <i className="fa-lg mdi mdi-message-outline" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          style={{ maxWidth: 25 }}
                          className="rounded-circle mr-2"
                          alt="100x100"
                          src={avatars.one}
                          data-holder-rendered="true"
                        ></img>
                        Fern Canopy
                        <i className="mdi mdi-checkbox-blank-circle text-secondary small ml-2" />
                      </td>
                      <td>@fc</td>
                      <td>
                        <i className="fa-lg mdi mdi-message-outline" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          style={{ maxWidth: 25 }}
                          className="rounded-circle mr-2"
                          alt="100x100"
                          src={avatars.four}
                          data-holder-rendered="true"
                        ></img>
                        Ez Baobab
                        <i className="mdi mdi-checkbox-blank-circle text-secondary small ml-2" />
                      </td>
                      <td>@ezza_b</td>
                      <td>
                        <i className="fa-lg mdi mdi-message-outline" />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
      </div>
    );
  }
}

export default Chat;
