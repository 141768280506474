import React, { Component } from "react";
import InviteButton from "../../components/invite-modal";
import { Card, CardBody, Table, Button } from "reactstrap";
import './../../assets/scss/style.css';

const avatars = {
  one: "https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg",
  two: "https://mdbootstrap.com/img/new/avatars/8.jpg",
  three: "https://mdbootstrap.com/img/new/avatars/1.jpg",
  four: "https://mdbootstrap.com/img/new/avatars/12.jpg",
  five: "https://mdbootstrap.com/img/new/avatars/10.jpg",
};

class Chat extends Component {
  state = {
    showInvite: false,
    showChat: false,
  };

  showInvite = () => {
    console.log(this.state.showInvite);
    this.setState({ showInvite: true });
    console.log(this.state.showInvite);
  };

  render() {
    return (
      <iframe src="http://leafnotes-iframe-v1.surge.sh" className="mediaiframe" style={{ width: '100%', height: '1800px'}} frameborder="0"></iframe>
    );
  }
}

export default Chat;
