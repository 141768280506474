import React, { useState } from "react";
import {
  Button,
  Input,
  Card,
  CardImg,
  CardTitle,
  CardSubtitle,
  CardText,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
const ModalExample = (props) => {
  const { className } = props;

  const [modal, setModal] = useState(false);

  const [tab, changeTab] = useState("text");

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Card>
        <CardImg top width="100%" src={props.img} />
        <CardBody>
          <CardTitle>{props.noteTitle}</CardTitle>
          <CardSubtitle>{props.noteSubtitle}</CardSubtitle>
          <CardText>{props.noteBody}</CardText>
          <Button
            className="pull-right btn-outline-secondary border rounded-circle p-1 btn-lg"
            onClick={toggle}
          >
            <i className="fa-lg mdi mdi-lead-pencil" />
          </Button>
        </CardBody>
      </Card>
      <Modal
        centered
        size="lg"
        isOpen={modal}
        toggle={toggle}
        className={className}
      >
        <ModalHeader toggle={toggle}>
          <div className="mt-2 h2">{props.noteTitle}</div>
        </ModalHeader>
        <ModalBody>
          <Button
            size="lg"
            onClick={() => changeTab("text")}
            className="btn mb-4 mr-4"
            color="success"
          >
            <i className="mr-2 mdi mdi-note-plus" />
            Text Input
          </Button>
          <Button
            size="lg"
            onClick={() => changeTab("media")}
            className="btn mb-4"
            color="primary"
          >
            <i className="mr-2 mdi mdi-note-plus" />
            Media Input
          </Button>

          {tab === "text" && (
            <Input
              type="textarea"
              rows="12"
              defaultValue={`${props.noteBody}

# Header
## Header 2

* Use bullets ...
* like this

- Or like this
- :) `}
            />
          )}
          {tab === "media" && (
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
              ></input>
              <label className="custom-file-label" for="customFile">
                Choose file
              </label>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={toggle}>
            Save
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalExample;
