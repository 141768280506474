import Notes from "../views/starter/notes.jsx";
import Chat from "../views/ui-components/chat.jsx";
import Media from "../views/ui-components/media.jsx";
import Boards from "../views/ui-components/boards.jsx";
import Settings from "../views/ui-components/settings.jsx";
import Profile from "../views/ui-components/profile.jsx";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;

  console.log(result);
}

var ThemeRoutes = [
  {
    path: "/dashboard",
    name: "Notes",
    icon: "mdi mdi-credit-card-multiple",
    component: Notes,
  },
  {
    path: "/chat",
    name: "Chat",
    icon: "mdi mdi-comment-processing-outline",
    component: Chat,
  },
  {
    path: "/media",
    name: "Media",
    icon: "mdi mdi-file-image",
    component: Media,
  },
  {
    path: "/boards",
    name: "Boards",
    icon: "mdi mdi-arrange-send-backward",
    component: Boards,
  },
  {
    path: "/settings/f1477d18-b9ee-11eb-8529-0242ac130999",
    name: "Settings",
    icon: "mdi mdi-image-filter-vintage",
    component: Settings,
  },
  {
    path: "/ " + makeid(15) + "/profile",
    name: "Profile",
    icon: "mdi mdi-account-circle",
    component: Profile,
  },
  { path: "/", pathTo: "/dashboard", name: "Dashboard", redirect: true },
];
export default ThemeRoutes;
