import { React, Component} from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Row, Col } from 'reactstrap';

const avatars = {
    one: 'https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg',
    two: 'https://mdbootstrap.com/img/new/avatars/8.jpg',
    three: 'https://mdbootstrap.com/img/new/avatars/1.jpg',
    four: 'https://mdbootstrap.com/img/new/avatars/12.jpg'
};


class Boards extends Component {

  state = {
    showBoard: false,
};

  newBoard = () => {
    this.setState({showBoard: true})
  }

    render() {
    return (
        <div>
            <div className="d-flex justify-content-between">
                <div className="h3">Boards</div>
                <Button className="btn mb-4" color="success" onClick={()=> this.newBoard()}><i className="mr-2 mdi mdi-plus"/>New board</Button>
            </div>
            <Row>
                <Col xs="12" md="4" sm="12">
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            <i className="mdi mdi-brush mr-2"> </i>
                            Design
                            <img style={{maxWidth: 25}} className="rounded-circle ml-3" alt="100x100" src={avatars.one} data-holder-rendered="true"></img>
                        </CardTitle>
                        <CardBody className="">
                            <div>
                                <div className="h4 mb-2">Sidebar Redesign <Badge className="float-right" color="primary" pill>3 Notes</Badge></div>
                                <div className="mt-4">As we look to overhaul our app's interface, we should consider bringing the sidebar theme in key with branding on the marketing site.</div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col xs="12" md="4" sm="12">
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            <i className="mdi mdi-flask mr-2"> </i>
                            Marketing Experiments
                            <img style={{maxWidth: 25}} className="rounded-circle ml-3" alt="100x100" src={avatars.two} data-holder-rendered="true"></img>
                            <img style={{maxWidth: 25}} className="rounded-circle ml-1" alt="100x100" src={avatars.four} data-holder-rendered="true"></img>
                        </CardTitle>
                        <CardBody className="">
                            <div>
                                <div className="h4 mb-2">In-App Copy <Badge className="float-right" color="primary" pill>14 Notes</Badge></div>
                                <div className="mt-4">Let's improve our new user activation rate! Where can we prompt key actions from new signups in the app?</div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col xs="12" md="4" sm="12">
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            <i className="mdi mdi-folder-account mr-2"> </i>
                            Employee Onboarding
                            <img style={{maxWidth: 25}} className="rounded-circle ml-3" alt="100x100" src={avatars.four} data-holder-rendered="true"></img>
                            <img style={{maxWidth: 25}} className="rounded-circle ml-1" alt="100x100" src={avatars.three} data-holder-rendered="true"></img>
                        </CardTitle>
                        <CardBody className="">
                            <div>
                                <div className="h4 mb-2">Onboarding Senior Dev <Badge className="float-right" color="primary" pill>2 Notes</Badge></div>
                                <div className="mt-4">As we look to overhaul our app's interface, we should consider bringing the sidebar theme in key with branding on the marketing site.</div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
              { this.state.showBoard ? 
                <Col xs="12" md="4" sm="12">
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            <i className="mdi mdi-folder-account mr-2"> </i>
                            Employee Onboarding
                            <img style={{maxWidth: 25}} className="rounded-circle ml-3" alt="100x100" src={avatars.four} data-holder-rendered="true"></img>
                            <img style={{maxWidth: 25}} className="rounded-circle ml-1" alt="100x100" src={avatars.three} data-holder-rendered="true"></img>
                        </CardTitle>
                        <CardBody className="">
                            <div>
                                <div className="h4 mb-2">Onboarding Senior Dev <Badge className="float-right" color="primary" pill>2 Notes</Badge></div>
                                <div className="mt-4">As we look to overhaul our app's interface, we should consider bringing the sidebar theme in key with branding on the marketing site.</div>
                            </div>
                        </CardBody>
                    </Card>
                </Col> : null }
            </Row>
        </div >
    );
    }
}

export default Boards;
