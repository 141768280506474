import React, { useState } from 'react';
import { Button, Input, Card, CardImg, CardTitle, CardSubtitle, CardText, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const InviteButton = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <div className=""><Button className="btn mb-4" color="success" onClick={toggle}><i className="mr-2 mdi mdi-account-plus"/>Invite</Button></div>
      <Modal centered size="lg" isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>
            <div className="mt-2 h2">{props.inviteTitle}</div>
        </ModalHeader>
        <ModalBody>
          <Input type="email" defaultValue={props.inviteBody}></Input>
        </ModalBody> 
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
          <Button color="primary" onClick={toggle}>{props.inviteAction}</Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default InviteButton;