import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";

const Buttons = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [plan, setPlan] = useState("");
  const [language, setLanguage] = useState("");

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (profile) {
      setName(profile.name);
      setEmail(profile.email);
      setRole(profile.role);
      setPlan(profile.plan);
      setLanguage(profile.language);
    } else {
      localStorage.setItem(
        "profile",
        JSON.stringify({
          name: "Dwight Schrute",
          email: "dwight@dundermiflin.com",
          role: "Assistant Regional Manager",
          plan: "Free",
          language: "English",
        })
      );
    }
  });

  const handleChange = (value, key) => {
    let profile = {};
    if (localStorage.getItem("profile")) {
      profile = JSON.parse(localStorage.getItem("profile"));
    }
    profile[key] = value;
    localStorage.setItem("profile", JSON.stringify(profile));
  };

  return (
    <div>
      <Row>
        <Col xs="12" md="0">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Your profile</h5>
              <Form>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    defaultValue={name}
                    onChange={(e) => handleChange(e.target.value, "name")}
                    className="mb-4"
                    type="email"
                    name="name"
                    id="name"
                    placeholder="Jane Kim"
                  />
                  <Label for="email">Email</Label>
                  <Input
                    defaultValue={email}
                    onChange={(e) => handleChange(e.target.value, "email")}
                    className="mb-4"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="jane@hibiscus.co"
                  />
                  <Label for="role">Role</Label>
                  <Input
                    onChange={(e) => handleChange(e.target.value, "role")}
                    className="mb-4"
                    type="select"
                    name="role"
                    id="role"
                  >
                    <option selected={role === "Product"}>Product</option>
                    <option selected={role === "Assistant Regional Manager"}>
                      Assistant Regional Manager
                    </option>
                    <option selected={role === "Marketing"}>Marketing</option>
                    <option selected={role === "Finance"}>Finance</option>
                    <option selected={role === "HR"}>HR</option>
                    <option selected={role === "Sales"}>Sales</option>
                  </Input>
                  <Label for="plan">Plan</Label>
                  <Input
                    onChange={(e) => handleChange(e.target.value, "plan")}
                    type="select"
                    className="mb-4"
                    name="plan"
                    id="plan"
                  >
                    <option selected={plan === "Free"}>Free</option>
                    <option selected={plan === "Pro"}>Pro</option>
                  </Input>
                  <Label for="language">Language</Label>
                  <Input
                    onChange={(e) => handleChange(e.target.value, "language")}
                    type="select"
                    name="plan"
                    id="plan"
                  >
                    <option selected={language === "English"}>English</option>
                    <option selected={language === "Spanish"}>Spanish</option>
                    <option selected={language === "French"}>French</option>
                    <option selected={language === "Portugees"}>
                      Portugees
                    </option>
                  </Input>
                </FormGroup>
              </Form>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Delete account</h5>
              <p className="card-text">
                This will delete your LeafNotes data and we will not be able to
                recover it.
              </p>
              <Button className="btn btn-danger">Delete account</Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Buttons;
